import "./style.css";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import * as dat from "dat.gui";
import { Float32BufferAttribute, ParametricBufferGeometry } from "three";

/**
 * Base
 */
// Debug
// const gui = new dat.GUI();

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

/**
 * Galaxy
 */

const PI = Math.PI;
const PIx2 = 2 * Math.PI;

const params = {
  count: 100000,
  particleSize: 0.01,
  galaxyRadius: 12,
  branches: 8,
  spin: 1,
  randomness: 0.2,
  randomnessPower: 3.5,
  insideColour: "#ff6030",
  outsideColour: "#1b3984",
};

let geometry = null;
let material = null;
let galaxy = null;

const generateGalaxy = () => {
  console.log("generating a galaxy");

  // destroy geometry ( clear the memory )
  if (galaxy !== null) {
    console.log("destroying geometry");
    geometry.dispose();
    material.dispose();
    scene.remove(galaxy);
  }

  console.log(scene);
  geometry = new THREE.BufferGeometry();

  const positions = new Float32Array(params.count * 3);
  const colors = new Float32Array(params.count * 3);

  const colorInside = new THREE.Color(params.insideColour);
  const colorOutside = new THREE.Color(params.outsideColour);

  for (let i = 0; i < params.count; i++) {
    const i3 = i * 3;

    const radius = params.galaxyRadius * Math.random();
    const spinAngle = radius * params.spin;
    const branchAngle = ((i % params.branches) / params.branches) * PIx2; // gives us a normalized value

    const randomX =
      Math.pow(Math.random(), params.randomnessPower) *
      (Math.random() < 0.5 ? 1 : -1);
    const randomY =
      Math.pow(Math.random(), params.randomnessPower) *
      (Math.random() < 0.5 ? 1 : -1);
    const randomZ =
      Math.pow(Math.random(), params.randomnessPower) *
      (Math.random() < 0.5 ? 1 : -1);

    positions[i3] = Math.cos(branchAngle + spinAngle) * radius + randomX;
    positions[i3 + 1] = randomY;
    positions[i3 + 2] = Math.sin(branchAngle + spinAngle) * radius + randomZ;

    const mixedColor = colorInside
      .clone()
      .lerp(colorOutside, radius / params.galaxyRadius);
    // colors
    colors[i3 + 0] = mixedColor.r;
    colors[i3 + 1] = mixedColor.g;
    colors[i3 + 2] = mixedColor.b;

    // flat disc
    // positions[i3] = Math.cos(branchAngle + i3 * params.spin) * radius;
    // positions[i3 + 1] = 0;
    // positions[i3 + 2] = Math.sin(branchAngle + i3 * params.spin) * radius;

    // positions[i3] = (Math.random() - 0.5) * params.galaxyRadius;
    // positions[i3 + 1] = (Math.random() - 0.5) * params.galaxyRadius;
    // positions[i3 + 2] = (Math.random() - 0.5) * params.galaxyRadius;
  }

  geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));
  geometry.setAttribute("color", new THREE.BufferAttribute(colors, 3));

  console.log(params);
  material = new THREE.PointsMaterial({
    color: new THREE.Color("#ffffff"),
    size: params.particleSize,
    sizeAttenuation: true,
    depthWrite: false,
    blending: THREE.AdditiveBlending,
    vertexColors: true,
  });

  galaxy = new THREE.Points(geometry, material);
  scene.add(galaxy);
};
generateGalaxy();

/**
 * GUI
 */
// gui
//   .add(params, "count")
//   .min(100)
//   .max(1000000)
//   .step(100)
//   .name("particle count")
//   .onFinishChange(generateGalaxy);

// gui
//   .add(params, "particleSize")
//   .min(0.01)
//   .max(0.1)
//   .step(0.001)
//   .name("particle size")
//   .onFinishChange(generateGalaxy);

// gui
//   .add(params, "galaxyRadius")
//   .min(1)
//   .max(100)
//   .step(1)
//   .name("radius")
//   .onFinishChange(generateGalaxy);

// gui
//   .add(params, "branches")
//   .min(1)
//   .max(20)
//   .step(1)
//   .name("branches")
//   .onFinishChange(generateGalaxy);

// gui
//   .add(params, "spin")
//   .min(0.01)
//   .max(1)
//   .step(0.01)
//   .name("spin")
//   .onFinishChange(generateGalaxy);

// gui
//   .add(params, "randomness")
//   .min(0.1)
//   .max(1)
//   .step(0.01)
//   .name("randomness")
//   .onFinishChange(generateGalaxy);

// gui
//   .add(params, "randomnessPower")
//   .min(1)
//   .max(10)
//   .step(0.1)
//   .name("power")
//   .onFinishChange(generateGalaxy);

// gui.addColor(params, "insideColour").onFinishChange(generateGalaxy);

// gui.addColor(params, "outsideColour").onFinishChange(generateGalaxy);

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  75,
  sizes.width / sizes.height,
  0.1,
  100
);
camera.position.x = 3;
camera.position.y = 3;
camera.position.z = 3;
scene.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;
// controls.maxDistance = 10;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

/**
 * Animate
 */
const clock = new THREE.Clock();

let theta = 0;
const tick = () => {
  const elapsedTime = clock.getElapsedTime();

  // Update controls
  controls.update();

  camera.position.x = 3 * Math.cos(theta);
  camera.position.z = 3 * Math.sin(theta);

  // camera.position.needsUpdate = true;
  // console.log(camera.position);

  // Render
  renderer.render(scene, camera);

  theta += 0.0005;

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
